<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    <div class="container">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-6">
                <div class="product-detail">
                  <div class="tab-content" id="v-pills-tabContent">
                    <div
                      class="tab-pane fade active show"
                      id="product-4"
                      role="tabpanel"
                    >
                      <div class="product-img">
                        <img
                          src="../../../../assets/images/product/main/production_equipement/Screen_Printer/SP3-C.jpg"
                          alt="img-4"
                          class="img-fluid mx-auto d-block"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
              <div class="col-xl-6">
                <div class="mt-4">
                  <h1 class="font-size-20 mb-3">
                    <strong> Product description: </strong>
                  </h1>
                  <h2>
                    SP3-C helps customers experience the printer of the future
                    smart era as well as the best printing quality
                  </h2>
                  <br>
                  <ul class="container">
                    <li>
                      <h5>
                        Realizes high-precision printing with alignment accuracy
                        of ±8um
                      </h5>
                    </li>
                    <li>
                      <h5>
                        Print offset automatically by feeding back defective SPI
                        printing
                      </h5>
                    </li>
                    <li><h5>For Mixed Production</h5></li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- end row -->
            <br /><br /><br /><br /><br /><br /><br />
            <h1><strong> Specifications </strong></h1>
            <br /><br />
            <div class="row container" style="font-size:18px;">
              <div class="container-fluid">
               
                  <div class="table-responsive">
                    <table class="table table-nowrap mb-0">
                      <tbody>
                        <tr>
                          <th scope="row" style="width: 400px">
                            Align Repeatability
                          </th>
                          <td style="font-size: 12px">T-±8um @ 6σ</td>
                        </tr>
                        <tr>
                          <th scope="row">Wet Print</th>
                          <td style="font-size: 12px">±15um @ 6σ</td>
                        </tr>
                        <tr>
                          <th scope="row">Cycle Time</th>
                          <td style="font-size: 12px">
                            5sec (Excl. Printing Time)
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Board Handling (mm)</th>
                          <td style="font-size: 12px">
                            Max. L350 × W250 (Single Lane) / L350 × W250 (Dual
                            Lane/Option)
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Stencil (mm)</th>
                          <td style="font-size: 12px">
                            L550 × W650/L650 × W550 ~ L736 × W736
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                
                <br><br>
                <h1><strong>Features</strong></h1>
                <br><br>

                <div class="row">
                  <div class="col-xl-6">
                    <div class="product-detail">
                      <div class="tab-content" id="v-pills-tabContent">
                        <div
                          class="tab-pane fade active show"
                          id="product-4"
                          role="tabpanel"
                        >
                          <div class="product-img">
                            <img
                              src="../../../../assets/images/product/main/production_equipement/Screen_Printer/sp3c feature/best_quality.jpg"
                              alt="img-4"
                              class="img-fluid mx-auto d-block"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- end product img -->
                  </div>
                  <div class="col-xl-6">
                    <div class="mt-4">
                      <h1 class="font-size-20 mb-3" style="color: #f60">
                        <strong> Best Quality </strong>
                      </h1>
                      <h3><strong> Printing Technology</strong></h3>
                      <p>
                        Allows printing in the direction of both X and Y axes
                        and realizes the best printing quality by applying SQG
                        Blade Tilting Technology.
                      </p>
                    </div>
                  </div>
                </div>
                <!-- end row -->
                <div class="row">
                  <div class="col-xl-6">
                    <div class="product-detail">
                      <div class="tab-content" id="v-pills-tabContent">
                        <div
                          class="tab-pane fade active show"
                          id="product-4"
                          role="tabpanel"
                        >
                          <div class="product-img">
                            <img
                              src="../../../../assets/images/product/main/production_equipement/Screen_Printer/sp3c feature/2d inspection.jpg"
                              alt="img-4"
                              class="img-fluid mx-auto d-block"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- end product img -->
                  </div>
                  <div class="col-xl-6">
                    <div class="mt-4">
                      <h1 class="font-size-20 mb-3" style="color: #f60">
                        <strong> 2D Inspection </strong>
                      </h1>
                      <h3><strong> Prevents Defective Printing</strong></h3>
                      <p>
                        Possible to perform 2DI of a PCB for insufficient
                        soldering, excessive soldering, and solder bridges as
                        well as foreign materials.
                      </p>
                    </div>
                  </div>
                </div>
                <!-- end row -->
                <div class="row">
                  <div class="col-xl-6">
                    <div class="product-detail">
                      <div class="tab-content" id="v-pills-tabContent">
                        <div
                          class="tab-pane fade active show"
                          id="product-4"
                          role="tabpanel"
                        >
                          <div class="product-img">
                            <img
                              src="../../../../assets/images/product/main/production_equipement/Screen_Printer/sp3c feature/spi feedback system.jpg"
                              alt="img-4"
                              class="img-fluid mx-auto d-block"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- end product img -->
                  </div>
                  <div class="col-xl-6">
                    <div class="mt-4">
                      <h1 class="font-size-20 mb-3" style="color: #f60">
                        <strong> SPI Feedback System </strong>
                      </h1>
                      <h3><strong> Improves Printing Quality</strong></h3>
                      <p>
                        Corrects the printing offset automatically by feeding
                        back to the machine the data inspected by the SPI
                        machine.
                      </p>
                    </div>
                  </div>
                </div>
                <!-- end row -->
                <div class="row">
                  <div class="col-xl-6">
                    <div class="product-detail">
                      <div class="tab-content" id="v-pills-tabContent">
                        <div
                          class="tab-pane fade active show"
                          id="product-4"
                          role="tabpanel"
                        >
                          <div class="product-img">
                            <img
                              src="../../../../assets/images/product/main/production_equipement/Screen_Printer/sp3c feature/cleaning technologie.jpg"
                              alt="img-4"
                              class="img-fluid mx-auto d-block"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- end product img -->
                  </div>
                  <div class="col-xl-6">
                    <div class="mt-4">
                      <h1 class="font-size-20 mb-3" style="color: #f60">
                        <strong> Cleaning Technology </strong>
                      </h1>
                      <h3>
                        <strong>
                          Improves User Convenience and Cleaning
                          Performance</strong
                        >
                      </h3>
                      <p>
                        Improves the user convenience of the Cleaner Module and
                        increases cleaning performance by applying the Solvent
                        Dipping technology.
                      </p>
                    </div>
                  </div>
                </div>
                <!-- end row -->
              </div>
            </div>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
};
</script>